<template>
  <section class="calendarbox">
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="headercontent.title" />
        </v-col>
        <v-col md="8">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="12" v-if="allUser.results">
                <v-select
                  :items="allUser.results"
                  item-text="fullname"
                  item-value="userHandle"
                  v-model="userHandle"
                  label="Employee"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="6">
                <label>Select Start Date</label>
                <div v-if="$route.params.id &&  allAttendance.results">
                  {{ allAttendance.results.startTime | formatDateTime }}
                </div>
                <div>
                  <v-datetime-picker
                    v-model="starttime"
                    outlined
                    label="Start Time"
                  ></v-datetime-picker>
                </div>
              </v-col>
              <v-col cols="6">
                <label>Select End Date</label>
                <div v-if="$route.params.id &&  allAttendance.results">
                  {{ allAttendance.results.endTime | formatDateTime }}
                </div>
                <div>
                  <v-datetime-picker
                    v-model="endtime"
                    outlined
                    label="End Time"
                  >
                  </v-datetime-picker>
                </div>
              </v-col>
              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                >
                  Add {{ headercontent.title }}
                </v-btn>
                <!-- <v-progress-circular
         
                  indeterminate
                ></v-progress-circular> -->

                <template v-if="allLogin.token != undefined">
                  <v-snackbar v-model="snackbar">
                    <span>
                      {{ allAttendance.message }}
                    </span>
                  </v-snackbar>
                </template>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AddAttendance",
  components: { PageHeader },
  data: () => ({
    valid: true,
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    msg: "Vue Image Upload and Resize Demo",
    hasImage: false,
    image: null,
    title: "",
    description: "",
    showDate: false,
    showStartTime: false,
    showEndTime: false,
    headercontent: {
      title: "Add Manual Attendance",
      addlink: "/dashboard/training/add",
      downloadlink: true,
    },
    content: "<h2>I am Example</h2>",
    editorOption: {
      // Some Quill options...
    },
    dates: [],
    picker: "",
    starttime: "",
    endtime: "",
    userHandle: "",
    snackbar: false,
  }),
  methods: {
    ...mapActions([
      "getUserForMenu",
      "createAttendanceAdmin",
      "fetchSingleAttendance",
      "updateAtteanceAdmin"
    ]),
    validate() {
      this.snackbar = true;
      let filter = {
        startTime: this.starttime,
        endTime: this.endtime,
       
      };
      if (this.$route.params.id == undefined) {
        filter.userHandle = this.userHandle;
        this.createAttendanceAdmin(filter);
      }else{
        filter.id = this.$route.params.id;
        this.updateAtteanceAdmin(filter);
      }
    },
    setImage: function(output) {
      this.hasImage = true;
      this.image = output;
      console.log("Info", output.info);
      console.log("Exif", output.exif);
    },

    showEndTimeFunc() {
      this.showEndTime = !this.showEndTime;
    },
    showStartTimeFunc() {
      this.showStartTime = !this.showStartTime;
    },
    showDateFunc() {
      this.showDate = !this.showDate;
    },
  },
  computed: {
    ...mapGetters(["allUser", "allAttendance", "allLogin"]),

    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  mounted() {
    console.log("this is current quill instance object", this.editor);
  },
  async created() {
    this.getUserForMenu();
    if (this.$route.params.id) {
      await this.fetchSingleAttendance(this.$route.params.id);
      this.userHandle = this.allAttendance.results.userHandle;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  width: 300px;
  min-height: 50px;
}

.imagebox {
  display: flex;
  max-width: 30%;
  // background: rgb(248, 248, 248);
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  margin-top: 20px;
  label {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
.editor-box {
  margin-top: 20px;
  margin-bottom: 20px;
}
.selectbox {
  border: 1px solid rgba(180, 180, 180, 1);
  padding: 15px;
  display: block;
  margin-bottom: 20px;
  cursor: pointer;
}
</style>
